// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface StudentCurrentTaskRepresentation
 */
export interface StudentCurrentTaskRepresentation {
  /**
   *
   * @type {string}
   * @memberof StudentCurrentTaskRepresentation
   */
  precinct: string
  /**
   *
   * @type {string}
   * @memberof StudentCurrentTaskRepresentation
   */
  taskActivityId: string
  /**
   *
   * @type {string}
   * @memberof StudentCurrentTaskRepresentation
   */
  assignmentId: string | null
  /**
   *
   * @type {number}
   * @memberof StudentCurrentTaskRepresentation
   */
  assignmentTaskId: number | null
}

/**
 * Check if a given object implements the StudentCurrentTaskRepresentation interface.
 */
export function instanceOfStudentCurrentTaskRepresentation(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'precinct' in value
  isInstance = isInstance && 'taskActivityId' in value
  isInstance = isInstance && 'assignmentId' in value
  isInstance = isInstance && 'assignmentTaskId' in value

  return isInstance
}

export function StudentCurrentTaskRepresentationFromJSON(
  json: any,
): StudentCurrentTaskRepresentation {
  return StudentCurrentTaskRepresentationFromJSONTyped(json, false)
}

export function StudentCurrentTaskRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StudentCurrentTaskRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    precinct: json['precinct'],
    taskActivityId: json['taskActivityId'],
    assignmentId: json['assignmentId'],
    assignmentTaskId: json['assignmentTaskId'],
  }
}

export function StudentCurrentTaskRepresentationToJSON(
  value?: StudentCurrentTaskRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    precinct: value.precinct,
    taskActivityId: value.taskActivityId,
    assignmentId: value.assignmentId,
    assignmentTaskId: value.assignmentTaskId,
  }
}
