import {
  Configuration as WlApiConfiguration,
  ActivitiesApi,
  BooksApi,
  CoinsApi,
  StudentsApi,
  ViewsApi,
  WhoAmIApi,
  AvatarsApi,
  EWIApi as WlExcitingWritingIdeasApi,
  TimeOnTaskApi,
  LessonsApi,
  InventoriesApi,
} from '@wl/api-client'
import {
  Configuration as NlpApiConfiguration,
  ExcitingWritingIdeasApi as NlpExcitingWritingIdeasApi,
} from '@wl/nlp-client'
import {
  Configuration as ReportApiConfiguration,
  SpellingsApi,
  WordwallsApi,
} from '@wl/report-client'

class ApiClient {
  private static instance: ApiClient | undefined

  private _activitiesApi: ActivitiesApi | undefined

  private _booksApi: BooksApi | undefined

  private _coinsApi: CoinsApi | undefined

  private _lessonsApi: LessonsApi | undefined

  private _studentsApi: StudentsApi | undefined

  private _viewsApi: ViewsApi | undefined

  private _whoAmIApi: WhoAmIApi | undefined

  private _avatarsApi: AvatarsApi | undefined

  private _wlExcitingWritingIdeasApi: WlExcitingWritingIdeasApi | undefined

  private _nlpExcitingWritingIdeasApi: NlpExcitingWritingIdeasApi | undefined

  private _timeOnTaskApi: TimeOnTaskApi | undefined

  private _inventoriesApi: InventoriesApi | undefined

  private _spellingsApi: SpellingsApi | undefined

  private _wordWallsApi: WordwallsApi | undefined

  private constructor(
    private wlApiConfig: WlApiConfiguration,
    private nlpApiConfig: NlpApiConfiguration,
    private reportApiConfig: ReportApiConfiguration,
  ) {}

  public static getInstance(): ApiClient {
    if (!ApiClient.instance) {
      const wlApiConfig =
        typeof window === 'undefined'
          ? new WlApiConfiguration({
              basePath: process.env.NEXT_API_URL,
            })
          : new WlApiConfiguration({
              basePath: window.location.origin,
            })

      const nlpApiConfig =
        typeof window === 'undefined'
          ? new NlpApiConfiguration({
              basePath: process.env.NLP_API_URL,
            })
          : new NlpApiConfiguration({
              // Prefix nlp basepath so it can be intercepted by our middleware
              basePath: new URL('/api/nlp', window.location.origin).href,
            })

      const reportApiConfig =
        typeof window === 'undefined'
          ? new ReportApiConfiguration({
              basePath: process.env.REPORT_API_URL,
            })
          : new ReportApiConfiguration({
              // Prefix nlp basepath so it can be intercepted by our middleware
              basePath: new URL('/api/report', window.location.origin).href,
            })

      ApiClient.instance = new ApiClient(
        wlApiConfig,
        nlpApiConfig,
        reportApiConfig,
      )
    }

    return ApiClient.instance
  }

  setWlBasePath(basePath: string) {
    this.wlApiConfig.config = new WlApiConfiguration({
      ...this.wlApiConfig.config,
      basePath,
    })
  }

  setNlpBasePath(basePath: string) {
    this.nlpApiConfig.config = new NlpApiConfiguration({
      ...this.nlpApiConfig.config,
      basePath,
    })
  }

  setReportBasePath(basePath: string) {
    this.reportApiConfig.config = new ReportApiConfiguration({
      ...this.reportApiConfig.config,
      basePath,
    })
  }

  get activitiesApi() {
    if (!this._activitiesApi) {
      this._activitiesApi = new ActivitiesApi(this.wlApiConfig)
    }

    return this._activitiesApi
  }
  get lessonsApi() {
    if (!this._lessonsApi) {
      this._lessonsApi = new LessonsApi(this.wlApiConfig)
    }
    return this._lessonsApi
  }

  get booksApi() {
    if (!this._booksApi) {
      this._booksApi = new BooksApi(this.wlApiConfig)
    }

    return this._booksApi
  }

  get coinsApi() {
    if (!this._coinsApi) {
      this._coinsApi = new CoinsApi(this.wlApiConfig)
    }

    return this._coinsApi
  }

  get studentsApi() {
    if (!this._studentsApi) {
      this._studentsApi = new StudentsApi(this.wlApiConfig)
    }

    return this._studentsApi
  }

  get viewsApi() {
    if (!this._viewsApi) {
      this._viewsApi = new ViewsApi(this.wlApiConfig)
    }

    return this._viewsApi
  }

  get whoAmIApi() {
    if (!this._whoAmIApi) {
      this._whoAmIApi = new WhoAmIApi(this.wlApiConfig)
    }

    return this._whoAmIApi
  }

  get avatarsApi() {
    if (!this._avatarsApi) {
      this._avatarsApi = new AvatarsApi(this.wlApiConfig)
    }

    return this._avatarsApi
  }

  get excitingWritingIdeasApi() {
    if (!this._wlExcitingWritingIdeasApi) {
      this._wlExcitingWritingIdeasApi = new WlExcitingWritingIdeasApi(
        this.wlApiConfig,
      )
    }

    return this._wlExcitingWritingIdeasApi
  }

  get nlpExcitingWritingIdeasApi() {
    if (!this._nlpExcitingWritingIdeasApi) {
      this._nlpExcitingWritingIdeasApi = new NlpExcitingWritingIdeasApi(
        this.nlpApiConfig,
      )
    }

    return this._nlpExcitingWritingIdeasApi
  }

  get timeOnTaskApi() {
    if (!this._timeOnTaskApi) {
      this._timeOnTaskApi = new TimeOnTaskApi(this.wlApiConfig)
    }

    return this._timeOnTaskApi
  }

  get inventoriesApi() {
    if (!this._inventoriesApi) {
      this._inventoriesApi = new InventoriesApi(this.wlApiConfig)
    }

    return this._inventoriesApi
  }

  get spellingsApi() {
    if (!this._spellingsApi) {
      this._spellingsApi = new SpellingsApi(this.reportApiConfig)
    }

    return this._spellingsApi
  }

  get wordWallsApi() {
    if (!this._wordWallsApi) {
      this._wordWallsApi = new WordwallsApi(this.reportApiConfig)
    }

    return this._wordWallsApi
  }
}

export const apiClient = ApiClient.getInstance()
